<template>
  <div class="public-header-container">
    <div class="public-content content">
      <div class="logo-item img-contain">
        <img v-if="websiteInfo.Logo" :src="websiteInfo.Logo" />
        <span v-else>{{ websiteInfo.SiteName }}</span>
      </div>
      <div class="tabs-item">
        <router-link :to="{path: '/'}" class="tab-item" :class="pageKey == 'home' ? 'active' : ''">首页</router-link>
        <router-link :to="{path: '/class'}" class="tab-item" :class="pageKey == 'class' ? 'active' : ''">商品分类</router-link>
        <router-link v-if="showSstz" :to="{path: '/notice'}" class="tab-item" :class="pageKey == 'notice' ? 'active' : ''">消息通知</router-link>
      </div>
      <div class="search-item">
        <div class="icon-item img-cover"><img src="../assets/pc-images/search-icon.png"></div>
        <div class="input"><input v-model="searchKey" type="text" placeholder="搜索"  @keyup.enter="handleSearch" /></div>
      </div>
      <div v-if="userInfo && userInfo.Id" class="user-item">
        <div class="user-info">
          <div class="header img-cover">
            <img v-if="userInfo.Header" :src="userInfo.Header" />
            <img v-else src="../assets/images/default-header.png" />
          </div>
          <div class="nickanme">
            <span v-if="userInfo.Nickname">{{ userInfo.Nickname }}</span>
            <span v-else>{{ userInfo.Phone | phoneFormat }}</span>
          </div>
        </div>
        <div class="user-nav">
          <div class="list-item">
            <div class="id-item">账号ID： <span>{{ userInfo.Id }}</span> <img class="copy" src="../assets/pc-images/copy-icon.png" @click="handleCopy(userInfo.Id)" /></div>
            <router-link v-if="userInfo.FzLevel > 0" :to="{ path: '/website' }" class="website-item" :class="'level_' + userInfo.FzLevel">
              <div class="name">{{ userInfo.FName }}</div>
              <div class="btn">去升级</div>
            </router-link>
            <div class="nav-list">
              <router-link :to="{ path: '/balance' }" class="nav">
                <div class="icon img-cover"><img src="../assets/pc-images/balance-icon.png" /></div>
                <div class="name">余额充值</div>
                <div class="right img-cover"><img src="../assets/pc-images/right-icon.png" /></div>
              </router-link>
              <router-link :to="{ path: '/order' }" class="nav">
                <div class="icon img-cover"><img src="../assets/pc-images/order-icon.png" /></div>
                <div class="name">订单管理</div>
                <div class="right img-cover"><img src="../assets/pc-images/right-icon.png" /></div>
              </router-link>
              <div class="nav" @click="handleShowSetting">
                <div class="icon img-cover"><img src="../assets/pc-images/setting-icon.png" /></div>
                <div class="name">账号设置</div>
                <div class="right img-cover"><img src="../assets/pc-images/right-icon.png" /></div>
              </div>
              <div class="nav" @click="showSecret = true">
                <div class="icon img-cover"><img src="../assets/pc-images/key-icon.png" /></div>
                <div class="name">密钥管理</div>
                <div class="right img-cover"><img src="../assets/pc-images/right-icon.png" /></div>
              </div>
              <router-link v-if="showSstz" :to="{ path: '/notice' }" class="nav">
                <div class="icon img-cover"><img src="../assets/pc-images/news-icon.png" /></div>
                <div class="name">消息通知</div>
                <div class="right img-cover"><img src="../assets/pc-images/right-icon.png" /></div>
              </router-link>
              <router-link :to="{ path: '/user' }" class="nav">
                <div class="icon img-cover"><img src="../assets/pc-images/home-icon.png" /></div>
                <div class="name">个人主页</div>
                <div class="right img-cover"><img src="../assets/pc-images/right-icon.png" /></div>
              </router-link>
              <div class="line"></div>
              <router-link :to="{ path: '/people' }" class="nav">
                <div class="icon img-cover"><img src="../assets/pc-images/user-icon.png" /></div>
                <div class="name">我的推广</div>
                <div class="right img-cover"><img src="../assets/pc-images/right-icon.png" /></div>
              </router-link>
              <div class="nav" @click="handleLogout">
                <div class="icon img-cover"><img src="../assets/pc-images/logout-icon.png" /></div>
                <div class="name">退出登录</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-link v-else :to="{ path: '/login' }" class="login-btn">登录</router-link>
    </div>
    <div class="right-container-item" v-if="!hideRight">
      <!-- <router-link :to="{ path: '/contact' }" class="contact">
        <div class="img img-cover"><img src="../assets/pc-images/contact-icon.png" /></div>
        <div class="name">联系我们</div>
      </router-link> -->
      <div class="contact">
        <div class="img img-cover"><img src="../assets/pc-images/contact-icon.png" /></div>
        <div class="name">联系我们</div>
        <div class="contact-popup">
          <div class="item-list">
            <div v-if="(lxfs.wxCode || lxfs.wxNum) && lxfs.status == 1" class="item">
              <div class="img img-contain">
                <img v-if="lxfs.wxCode" :src="lxfs.wxCode" />
                <img v-else src="../assets/images/news-nav-icon-4.png" />
              </div>
              <div class="value">
                <span>微信: {{ lxfs.wxNum }}</span>
              </div>
            </div>
            <div v-if="lxfs.qq && lxfs.status == 1" class="item">
              <div class="img img-contain">
                <img src="../assets/images/qq-icon.png" />
              </div>
              <div class="value">
                <span>QQ: {{ lxfs.qq }}</span>
              </div>
            </div>
            <div v-if="websiteInfo.Qq && websiteInfo.Qq.num && websiteInfo.Qq.status == 1" class="item">
              <div class="img img-contain">
                <img src="../assets/images/qq-icon.png" />
              </div>
              <div class="value">
                <span>QQ: {{ websiteInfo.Qq.num }}</span>
              </div>
            </div>
            <div v-if="websiteInfo.Wx && (websiteInfo.Wx.num || websiteInfo.Wx.code) && websiteInfo.Wx.status == 1" class="item">
              <div class="img img-contain">
                <img v-if="websiteInfo.Wx.code" :src="websiteInfo.Wx.code" />
                <img v-else src="../assets/images/news-nav-icon-4.png" />
              </div>
              <div class="value">
                <span>微信: {{ websiteInfo.Wx.num }}</span>
              </div>
            </div>
            <div v-if="websiteInfo.ContactType1 && websiteInfo.Contact1 && (websiteInfo.Contact1.num || websiteInfo.Contact1.code) && websiteInfo.Contact1.status == 1" class="item">
              <div class="img img-contain">
                <img v-if="websiteInfo.ContactType1 == 1" src="../assets/images/qq-icon.png" />
                <img v-else-if="websiteInfo.ContactType1 == 3" src="../assets/images/tg-icon.png" />
                <img v-else-if="websiteInfo.ContactType1 == 2 && websiteInfo.Contact1.code" :src="websiteInfo.Contact1.code" />
                <img v-else src="../assets/images/news-nav-icon-4.png" />
              </div>
              <div class="value">
                <span v-if="websiteInfo.ContactType1 == 1">QQ: {{ websiteInfo.Contact1.num }}</span>
                <span v-if="websiteInfo.ContactType1 == 2">微信: {{ websiteInfo.Contact1.num }}</span>
                <span v-if="websiteInfo.ContactType1 == 3">Telegram: {{ websiteInfo.Contact1.num }}</span>
              </div>
            </div>
            <div v-if="websiteInfo.ContactType2 && websiteInfo.Contact2 && (websiteInfo.Contact2.num || websiteInfo.Contact2.code) && websiteInfo.Contact2.status == 1" class="item">
              <div class="img img-contain">
                <img v-if="websiteInfo.ContactType2 == 1" src="../assets/images/qq-icon.png" />
                <img v-else-if="websiteInfo.ContactType2 == 3" src="../assets/images/tg-icon.png" />
                <img v-else-if="websiteInfo.ContactType2 == 2 && websiteInfo.Contact2.code" :src="websiteInfo.Contact2.code" />
                <img v-else src="../assets/images/news-nav-icon-4.png" />
              </div>
              <div class="value">
                <span v-if="websiteInfo.ContactType2 == 1">QQ: {{ websiteInfo.Contact2.num }}</span>
                <span v-if="websiteInfo.ContactType2 == 2">微信: {{ websiteInfo.Contact2.num }}</span>
                <span v-if="websiteInfo.ContactType2 == 3">Telegram: {{ websiteInfo.Contact2.num }}</span>
              </div>
            </div>
            <div v-if="websiteInfo.ContactType3 && websiteInfo.Contact3 && (websiteInfo.Contact3.num || websiteInfo.Contact3.code) && websiteInfo.Contact3.status == 1" class="item">
              <div class="img img-contain">
                <img v-if="websiteInfo.ContactType3 == 1" src="../assets/images/qq-icon.png" />
                <img v-else-if="websiteInfo.ContactType3 == 3" src="../assets/images/tg-icon.png" />
                <img v-else-if="websiteInfo.ContactType3 == 2 && websiteInfo.Contact3.code" :src="websiteInfo.Contact3.code" />
                <img v-else src="../assets/images/news-nav-icon-4.png" />
              </div>
              <div class="value">
                <span v-if="websiteInfo.ContactType3 == 1">QQ: {{ websiteInfo.Contact3.num }}</span>
                <span v-if="websiteInfo.ContactType3 == 2">微信: {{ websiteInfo.Contact3.num }}</span>
                <span v-if="websiteInfo.ContactType3 == 3">Telegram: {{ websiteInfo.Contact3.num }}</span>
              </div>
            </div>
            <div v-if="websiteInfo.ContactType4 && websiteInfo.Contact4 && (websiteInfo.Contact4.num || websiteInfo.Contact4.code) && websiteInfo.Contact4.status == 1" class="item">
              <div class="img img-contain">
                <img v-if="websiteInfo.ContactType4 == 1" src="../assets/images/qq-icon.png" />
                <img v-else-if="websiteInfo.ContactType4 == 3" src="../assets/images/tg-icon.png" />
                <img v-else-if="websiteInfo.ContactType4 == 2 && websiteInfo.Contact4.code" :src="websiteInfo.Contact4.code" />
                <img v-else src="../assets/images/news-nav-icon-4.png" />
              </div>
              <div class="value">
                <span v-if="websiteInfo.ContactType4 == 1">QQ: {{ websiteInfo.Contact4.num }}</span>
                <span v-if="websiteInfo.ContactType4 == 2">微信: {{ websiteInfo.Contact4.num }}</span>
                <span v-if="websiteInfo.ContactType4 == 3">Telegram: {{ websiteInfo.Contact4.num }}</span>
              </div>
            </div>
            <!-- <div class="btn blue">尽量联系网页客服</div> -->
          </div>
        </div>
      </div>
      <div class="btns-item">
        <router-link :to="{path: '/question'}" class="btn">
          <div class="img img-cover"><img src="../assets/pc-images/question-icon.png" /></div>
          <div class="name">常见问题</div>
        </router-link>
        <div class="btn" @click="scrollToTop">
          <div class="img img-cover"><img src="../assets/pc-images/top-icon.png" /></div>
          <div class="name">置顶</div>
        </div>
      </div>
    </div>
    <el-dialog class="pc-dialog" title="密钥管理" width="810px" :visible.sync="showSecret">
      <div class="secret-container">
        <div class="item">
          <div class="name">APPID：</div>
          <div class="input-item">
            <div class="input"><input v-model="userInfo.AppId" disabled type="text" /></div>
            <div class="icon img-cover" @click="handleCopy(userInfo.AppId)"><img src="../assets/pc-images/copy-blue-icon.png" /></div>
          </div>
        </div>
        <div class="item">
          <div class="name">AppSecret：</div>
          <div class="input-item">
            <div class="input"><input v-model="userInfo.AppSecret" disabled type="text" /></div>
            <div class="icon img-cover" @click="handleCopy(userInfo.AppSecret)"><img src="../assets/pc-images/copy-blue-icon.png" /></div>
          </div>
        </div>
        <div class="btn-item">
          <div class="btn" @click="handleResetSecretSubmit">
            <van-loading v-if="secretSubmiting" color="#ffffff" />
            <span v-else>重置密钥</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="pc-dialog" title="账号设置" width="810px" :visible.sync="settingPopupData.show">
      <div class="setting-container">
        <div class="item">
          <div class="title">头像：</div>
          <van-uploader name="Photo" :preview-image="false" :deletable="false" :after-read="handleUploadHeader">
            <div class="header img-cover">
              <img v-if="settingPopupData.Header" :src="settingPopupData.Header" />
              <img v-else src="../assets/images/default-header.png" />
            </div>
          </van-uploader>
        </div>
        <div class="item">
          <div class="title">昵称：</div>
          <div class="input">
            <input v-model="settingPopupData.Nickname" type="text" />
            <div class="edit img-cover"><img src="../assets/pc-images/edit-icon.png" /></div>
          </div>
        </div>
        <div class="item">
          <div class="title">ID：</div>
          <div class="input">
            <input v-model="settingPopupData.Id" disabled type="text" />
          </div>
        </div>
        <div class="item">
          <div class="title">手机号：</div>
          <div class="input">
            <input v-model="settingPopupData.Phone" disabled type="text" />
          </div>
        </div>
        <div class="btn-item">
          <div class="btn" @click="handleLogout">退出登录</div>
          <div class="btn blue" @click="handleSettingSubmit">确认修改</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'PcPublicHeader',
  props: {
    pageKey: String,
    goodsName: String,
    hideRight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showSstz: false,
      searchKey: '',
      showSecret: false,
      secretSubmiting: false,
      settingPopupData: {
        show: false,
        edit: false,
        submiting: false,
        Header: '',
        Nickname: '',
        Id: '',
        Phone: ''
      },
      lxfs: { wxCode: '', wxNum: '', qq: '', status: '' }
    }
  },
  watch: {
    goodsName(val) {
      this.searchKey = val
    }
  },
  filters: {
    phoneFormat(text) {
      if (text) {
        return text.substr(0, 3) + '****' + text.substr(-4)
      }
      return ''
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  mounted() {
    this.handleGetLxfs()
    this.handleGetSetting()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    async handleGetSetting() {
      var res = await this.$API.getSet.post({
        'Key': 'cz_show'
      })
      const { userInfo } = this
      if (userInfo && userInfo.Id) {
        if (userInfo.UserIsSs == 1 && parseFloat(userInfo.AllMoney) >= parseFloat(res.info)) {
          this.showSstz = true
        } else {
          this.showSstz = false
        }
      } else {
        if (res.info == -1) {
          this.showSstz = true
        } else {
          this.showSstz = false
        }
      }
    },
    async handleGetLxfs() {
      var res = await this.$API.getSet.post({
        'Key': 'lxfs'
      })
      const lxfs = JSON.parse(res.info)
			this.lxfs = {
				qq: lxfs.qq,
				wxCode: lxfs.wxCode,
				wxNum: lxfs.wxNum,
				status: lxfs.status
			}
    },
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    handleSearch() {
      const { pageKey, searchKey } = this
      if (pageKey == 'class') {
        this.$emit('handleSearchSubmit', searchKey)
      } else {
        this.$router.push({ path: '/class', query: { searchKey: searchKey } })
      }
    },
    scrollToTop() {
      const scrollAmount = window.scrollY;
      // 使用requestAnimationFrame以保证平滑的滚动效果
      const scrollTop = () => {
        const now = performance.now()
        const time = (now - startTime) / 1000
        if (time < 1) {
          window.scrollTo(0, startY - ((time ** 2) * (scrollAmount / 2)))
          requestAnimationFrame(scrollTop)
        } else {
          window.scrollTo(0, 0)
        }
      }
    
      const startY = window.scrollY; // 获取当前滚动位置
      const startTime = performance.now()
    
      scrollTop()
    },
    handleLogout() {
      this.$dialog.confirm({
        type: 'info',
        className: 'pc-dialog',
        title: '提示',
        message: '确定要退出登录吗？',
        cancelButtonText: '取消',
        confirmButtonText: '确认',
      }).then(() => {
        this.$TOOL.data.clear()
        window.location.reload()
      }).catch(() => {
      })
    },
    handleResetSecretSubmit() {
      this.$dialog.confirm({
        type: 'info',
        className: 'pc-dialog',
        title: '提示',
        message: '确定要重置密钥吗？',
        cancelButtonText: '取消',
        confirmButtonText: '确认重置',
      }).then(async () => {
        const { userInfo } = this
        this.secretSubmiting = true
        var res = await this.$API.resetKey.post({
          AppId: userInfo.AppId,
          AccessKeyId: userInfo.AppSecret,
        })
        this.secretSubmiting = false
        if(res.error == 0){
          this.$toast.success(res.info)
          this.GetUserInfo()
        } else {
          this.$dialog.alert({
            type: 'danger',
            className: 'pc-dialog',
            title: '提示',
            message: res.info,
            confirmButtonText: '确定',
          })
          return false
        }
      }).catch(() => {
      })
    },
    handleShowSetting() {
      const { userInfo } = this
      this.settingPopupData = {
        show: true,
        edit: false,
        submiting: false,
        Header: userInfo.Header,
        Nickname: userInfo.Nickname,
        Id: userInfo.Id,
        Phone: userInfo.Phone
      }
    },
    async handleUploadHeader(file) {
      var res = await this.$API.upload.post(file[0])
      if (res.error == 0) {
        this.settingPopupData.Header = res.info
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定'
        })
      }
    },
    async handleSettingSubmit() {
      const { settingPopupData } = this
      var res = await this.$API.userInfo.post({
        Switch: 2,
        Header: settingPopupData.Header,
        Nickname: settingPopupData.Nickname
      })
      if (res.error == 0) {
        this.$toast.success(res.info)
        this.GetUserInfo()
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    },
  }
}
</script>

<style scoped lang="less">
.public-header-container {
  width: 100%;
  height: 66px;
  background-color: #fff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, .06);
  .content {
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    .logo-item {
      display: flex;
      cursor: pointer;
      width: 130px;
      height: 46px;
      justify-content: center;
      align-items: center;
      span {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
      }
    }
    .tabs-item {
      flex: 1;
      display: flex;
      .tab-item {
        position: relative;
        height: 66px;
        color: #3d3d42;
        font-weight: bold;
        margin-left: 80px;
        line-height: 66px;
        &.active {
          color: #6a5ffe;
          &::after {
            content: "";
            height: 4px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #6a5ffe;
          }
        }
      }
    }
    .search-item {
      width: 520px;
      height: 40px;
      margin-right: 30px;
      background-color: #f5f5f5;
      border: 1px solid #ccc;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon-item {
        width: 24px;
        height: 24px;
        margin: 0 14px;
      }
      .input {
        flex: 1;
      }
    }
    .user-item {
      position: relative;
      &:hover {
        .user-nav {
          display: block;
        }
      }
      .user-info {
        display: flex;
        align-items: center;
        .header {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 16px;
        }
        .nickanme {
          width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .user-nav {
        display: none;
        position: absolute;
        top: 42px;
        right: 0;
        padding-top: 22px;
        z-index: 100;
        .list-item {
          width: 260px;
          border-radius: 12px;
          background-color: #fff;
          box-shadow: 0 0 24px 0 rgba(0, 0, 0, .16);
          .id-item {
            padding: 24px 16px 24px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #9fafc1;
            font-weight: bold;
            span {
              flex: 1;
              color: #333;
            }
            .copy {
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }
          .website-item {
            cursor: pointer;
            width: 220px;
            height: 48px;
            border-radius: 12px 12px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 20px 12px;
            &.level_50 {
              background: linear-gradient( 90deg, #f7f7f7 0%, #ccc 100%);
            }
            &.level_51 {
              background: linear-gradient( 134deg, #fffdf8 0%, #ffc768 75%, #fff3e4 100%);
            }
            &.level_52 {
              background: linear-gradient( 135deg, #4a3e23 0%, #0f0b08 75%, #403723 100%);
              .name {
                color: #ebd29a;
              }
            }
            .name {
              margin-left: 12px;
              color: #333;
              font-weight: bold;
            }
            .btn {
              margin-right: 10px;
              width: 70px;
              height: 28px;
              text-align: center;
              line-height: 28px;
              border-radius: 14px;
              color: #f5672f;
              background: linear-gradient( 90deg, #fff 0%, #ffc869 100%);
            }
          }
          .nav-list {
            padding: 2px 4px;
            .nav {
              cursor: pointer;
              width: 100%;
              height: 48px;
              margin-bottom: 8px;
              border-radius: 6px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              &:hover {
                background-color: #f6f6f6;
              }
              .icon {
                margin: 0 12px 0 20px;
                width: 22px;
                height: 22px;
              }
              .name {
                flex: 1;
                color: #2a2a2b;
                font-weight: 500;
              }
              .right {
                width: 20px;
                height: 20px;
                margin-right: 12px;
              }
            }
            .line {
              margin: 0 16px 8px;
              height: 2px;
              background-color: #f0f0f0;
            }
          }
        }
      }
    }
    .login-btn {
      cursor: pointer;
      width: 100px;
      height: 40px;
      border-radius: 6px;
      background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
      color: #fff;
      text-align: center;
      line-height: 40px;
    }
  }
}
.right-container-item {
  position: fixed;
  z-index: 99;
  right: 40px;
  bottom: 40px;
  width: 72px;
  .contact {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 72px;
    height: 86px;
    &:hover {
      .contact-popup {
        display: block;
      }
    }
    .img {
      width: 68px;
      height: 68px;
    }
    .name {
      margin-top: -10px;
      width: 72px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .16);
      color: #6a5ffe;
      font-size: 14px;
      font-weight: bold;
    }
    .contact-popup {
      position: absolute;
      display: none;
      bottom: 0;
      right: 72px;
      padding-right: 6px;
      .item-list {
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);
        width: 280px;
        background-color: #fff;
        border-radius: 12px;
        padding: 10px;
        overflow: hidden;
        .item {
          width: 120px;
          float: left;
          margin: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .img {
            width: 68px;
            height: 68px;
          }
          .value {
            font-size: 12px;
            color: #333;
          }
        }
      }
    }
  }
  .btns-item {
    margin-top: 20px;
    width: 44px;
    background-color: #fff;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .16);
    border-radius: 4px;
    padding: 0 14px;
  }
  .btn  {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 84px;
    border-bottom: 2px solid #dce1e6;
    &:nth-last-child(1) {
      border-bottom: 0;
    }
    .img {
      width: 24px;
      height: 24px;
    }
    .name {
      margin-top: 8px;
      line-height: 15px;
      font-size: 10px;
      color: #0b212c;
    }
  }
}
.secret-container {
  padding: 40px 30px 0;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .name {
      width: 100px;
      text-align: right;
      font-size: 14px;
      color: #333;
    }
    .input-item {
      width: 640px;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      background-color: #f5f5f5;
      border-radius: 6px;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input {
        flex: 1;
      }
      .icon {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-left: 20px;
      }
    }
  }
  .btn-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #f3f5f8;
    padding: 30px 0;
    .btn {
      cursor: pointer;
      width: 220px;
      height: 44px;
      border-radius: 6px;
      background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
      text-align: center;
      line-height: 44px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
.setting-container {
  padding-top: 20px;
  .item {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .title {
      width: 62px;
      text-align: right;
      font-size: 14px;
      color: #333;
    }
    .header {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
    }
    .input {
      width: 640px;
      height: 38px;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #ccc;
      border-radius: 6px;
      background-color: #f5f5f5;
      input {
        flex: 1;
        color: #6a5ffe;
        &:disabled {
          color: #9fafc1;
        }
      }
      .edit {
        width: 20px;
        height: 20px;
      }
    }
  }
  .btn-item {
    border-top: 1px solid #f3f5f8;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    .btn {
      cursor: pointer;
      margin: 0 30px;
      width: 220px;
      height: 44px;
      box-sizing: border-box;
      border: 1px solid #9fafc1;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #71757d;
      font-size: 14px;
      font-weight: bold;
      &.blue {
        border-width: 0;
        background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
        color: #fff;
      }
    }
  }
}
</style>
